/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslationObject} from '@ngx-translate/core';
import {map, Observable, throwError} from 'rxjs';
import environment from '@environment';
import {catchError} from 'rxjs/operators';

interface IApiResponse {
    result: TranslationResult;
    message: string;
    statusCode: number;
    timestamp: string;
    isSuccessStatusCode: boolean;
}

interface TranslationResult {
    id: string;
    key: string;
    translations: {
        [languageCode: string]: string;
    };
}

@Injectable()
export class TranslationLoaderService implements TranslateLoader {
    constructor(private readonly _httpClient: HttpClient) {}

    public getTranslation(lang = 'en'): Observable<TranslationObject> {
        const apiUrl = environment.defaultConfiguration.auth.translationsApiUri;
        const apiVersion = environment.defaultConfiguration.auth.translationsApiVersion;
        return this._httpClient
            .get<IApiResponse>(`${apiUrl}/public/AppTranslation/LMS?api-version=${apiVersion}`, {
                headers: {skip: 'true'},
            })
            .pipe(
                map(response => JSON.parse(response.result.translations[lang])),
                catchError(err => throwError(() => new Error(err?.error?.title || 'Error loading translations'))),
            );
    }
}
